import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Amora Real Estate</title>
        <meta name="description" content="Amora real estate and construction" />;
      </Helmet>
      <div className="App">
        <head>
          <title>Amora Real Estate</title>
          <meta name="description" content="Amora Real Estate" />
          {/* Other metadata tags */}
        </head>
        <main className="flex min-h-screen flex-col items-center justify-between">
          {/* Hero */}
          <div
            className="h-screen w-screen"
            style={{
              backgroundImage: `url('/assets/images/white-cube-house.png')`
            }}>
            {/* NAVBAR */}
            <div className="flex w-full justify-between bg-transparent px-20 py-6">
              <h1 className="text-2xl font-bold uppercase text-white">Amora</h1>
              <nav>
                <ul className="flex space-x-10 text-white">
                  <li>About</li>
                  <li>Service</li>
                  <li>Testimonial</li>
                  <li>Contact</li>
                </ul>
              </nav>
            </div>
            {/* NAVBAR */}
            <header className="mt-32 space-y-4">
              <h1 className="text-center text-3xl font-semibold text-white">
                Amora Construction & Real <br /> Estate Development
              </h1>
              <p className="text-center text-sm font-light text-white">
                Membangun Pondasi, Membangun Masa Depan
                <br /> Mitra Anda dalam Kebanggaan Konstruksi
              </p>
            </header>
          </div>
          {/* Floating Text */}
          <div className="-mt-8 rounded-full bg-[#286276] p-3 px-20 text-white">
            <p className="text-center text-xs">
              Hunian, nyaman, asri dan elegant untuk kaum milenial.
            </p>
            <h1>Living in Friendly Environment and Milenial Vibes</h1>
          </div>
          {/* Company Information */}
          <div className="flex min-h-screen w-full items-center">
            <img
              src={'/assets/images/house-object.png'}
              alt="House"
              width={700}
              height={700}
              className="object-contain"
            />
            <section className="flex w-1/2 flex-col justify-center space-y-1 p-10 text-left">
              <p>Tentang</p>
              <h1 className="text-xl font-semibold">PT Amora Gunung Mas</h1>
              <p className="pt-4">
                PT AMORA GUNUNG MAS mulai berdiri pada tahun 2023 berdasarkan pada Akta Pendirian PT
                Pada tanggal 1 Maret 2023 nomor 03 dikantor Notaris Anesta Chrisanti, S.H., M.Kn.
                Kami merupakan perusahaan swasta dengan kualifikasi Kecil & Menengah yang bergerak
                di bidang jasa konstruksi | sipil | arsitektur | mekanikal & elektrikal | surveyor |
                supplier | konsultan properti yaitu sebagai pengembangan perumahan (Developer).
                Dengan dukungan dari tenaga-tenaga ahli yang teruji dan berpengalaman di bidangnya,
                kami memiliki visi untuk turut serta dalam melaksanakan program pembangunan nasional
                dengan mutu dan kualitas yang terjaga. Sebagai komitmen kami dalam meningkatkan ku
                alitas pelayanan, kami juga memiliki divisi perencanaan. Sehingga kami dapat
                merefleksikan keinginan klien kami dalam wujud desain yang tentunya tetap
                memperhatikan kaidah- kaidah arsitektur. Kami tumbuh berkat dukungan dan kerja keras
                dari seluruh staf dan karyawan. Dengan kepercayaan besar yang diberikan oleh para
                klien kami, kami selalu berusaha memberikan pelayanan yang terbaik.Direktur
                Utama,TARKIM FIRMAN PURBA
              </p>
            </section>
          </div>
          {/* Our Services */}
          <div className="relative flex h-screen w-full items-center">
            <img
              src={'/assets/svg/blue-wave.svg'}
              alt="House"
              className="absolute left-0 top-0 object-contain"
            />
            <section className="space-y-10 px-10">
              <h1 className="text-xl font-semibold">Pelayanan Kami</h1>
              <div className="grid grid-cols-4 gap-10">
                <article className="space-y-3 text-left">
                  <img
                    src={'/assets/images/house-object.png'}
                    alt="House"
                    className="object-contain"
                  />
                  <section className="space-y-2">
                    <h1 className="font-semibold">Konstruksi Bangunan</h1>
                    <p className="text-sm font-light">
                      Add WooCommerce plugin to any WordPress site and set up a new store in
                      minutes.
                    </p>
                    <a className="text-sm font-medium text-blue-600">Lihat detail</a>
                  </section>
                </article>
                <article className="space-y-3 text-left">
                  <img
                    src={'/assets/images/house-object.png'}
                    alt="House"
                    className="object-contain"
                  />
                  <section className="space-y-2">
                    <h1 className="font-semibold">Konstruksi Bangunan</h1>
                    <p className="text-sm font-light">
                      Add WooCommerce plugin to any WordPress site and set up a new store in
                      minutes.
                    </p>
                    <a className="text-sm font-medium text-blue-600">Lihat detail</a>
                  </section>
                </article>
                <article className="space-y-3 text-left">
                  <img
                    src={'/assets/images/house-object.png'}
                    alt="House"
                    className="object-contain"
                  />
                  <section className="space-y-2">
                    <h1 className="font-semibold">Konstruksi Bangunan</h1>
                    <p className="text-sm font-light">
                      Add WooCommerce plugin to any WordPress site and set up a new store in
                      minutes.
                    </p>
                    <a className="text-sm font-medium text-blue-600">Lihat detail</a>
                  </section>
                </article>
                <article className="space-y-3 text-left">
                  <img
                    src={'/assets/images/house-object.png'}
                    alt="House"
                    className="object-contain"
                  />
                  <section className="space-y-2">
                    <h1 className="font-semibold">Konstruksi Bangunan</h1>
                    <p className="text-sm font-light">
                      Add WooCommerce plugin to any WordPress site and set up a new store in
                      minutes.
                    </p>
                    <a className="text-sm font-medium text-blue-600">Lihat detail</a>
                  </section>
                </article>
              </div>
            </section>
          </div>
          {/* Our Works */}
          <div
            className="relative flex h-max w-full items-center overflow-hidden text-white"
            style={{
              backgroundImage: `url('/assets/svg/rect-background.svg')`
            }}>
            <img
              src={'/assets/svg/blue-wave.svg'}
              alt="House"
              className="absolute left-0 top-0 object-contain"
            />
            <img
              src={'/assets/svg/graphic-decoration-1.svg'}
              alt="House"
              width={300}
              height={300}
              className="object-contain"
            />
            <section className="flex items-center">
              {/* <img
              src={'https://www.magnetpro.co.id/files/FL_resza_4055_GriyaSrimahi-web02.jpg'}
              alt="House"
              width={300}
              height={300}
              className="rounded-lg bg-white object-contain shadow"
            /> */}
              <section className="space-y-4">
                <h1 className="text-xl font-bold">Proyek Perumahan Green Srimahi Residence</h1>
                <p>
                  WooCommerce is developer friendly, too. Built with a REST API, WooCommerce is
                  scalable and can integrate with virtually any service. Design a complex store from
                  scratch, extend a store for a client, or simply add a single product to a
                  WordPress site—your store, your way.
                </p>
                <p>
                  WooCommerce is one of the fastest-growing eCommerce communities. We’re proud that
                  the helpfulness of the community and a wealth of online resources are frequently
                  cited as reasons our users love it. There are 80+ meetups worldwide!
                </p>
              </section>
              <img
                src={'/assets/svg/graphic-decoration-2.svg'}
                alt="House"
                width={300}
                height={300}
                className="object-contain"
              />
            </section>
          </div>
          {/* Testimonial */}
          <div className="relative flex h-screen w-full flex-col items-center justify-center space-y-5 bg-gray-50">
            <img
              src={'/assets/svg/graphic-dots-combined.svg'}
              className="absolute left-1/4 top-0 z-0 size-[600px] object-contain"
            />
            <img
              src={
                'https://akcdn.detik.net.id/visual/2023/06/19/avatar-the-last-airbender-2024-3_169.jpeg?w=480&q=90'
              }
              alt="House"
              className="absolute left-72 top-32 size-20 rounded-full object-cover"
            />
            <h1 className="text-2xl font-bold">Trusted by Agencies & Store Owners</h1>
            <img
              src={
                'https://akcdn.detik.net.id/visual/2023/06/19/avatar-the-last-airbender-2024-3_169.jpeg?w=480&q=90'
              }
              alt="House"
              className="absolute left-72 top-32 size-20 rounded-full object-cover"
            />
            <img
              src={
                'https://akcdn.detik.net.id/visual/2023/06/19/avatar-the-last-airbender-2024-3_169.jpeg?w=480&q=90'
              }
              alt="House"
              className="absolute bottom-32 left-64 size-16 rounded-full object-cover"
            />
            <img
              src={
                'https://akcdn.detik.net.id/visual/2023/06/19/avatar-the-last-airbender-2024-3_169.jpeg?w=480&q=90'
              }
              alt="House"
              className="absolute right-72 top-32 size-20 rounded-full object-cover"
            />
            <img
              src={
                'https://akcdn.detik.net.id/visual/2023/06/19/avatar-the-last-airbender-2024-3_169.jpeg?w=480&q=90'
              }
              alt="House"
              className="absolute right-44 top-80 size-28 rounded-full object-cover"
            />
            <img
              src={
                'https://akcdn.detik.net.id/visual/2023/06/19/avatar-the-last-airbender-2024-3_169.jpeg?w=480&q=90'
              }
              alt="House"
              className="absolute bottom-32 right-72 size-14 rounded-full object-cover"
            />

            <section className="z-10 rounded-3xl bg-white">
              <div className="container mx-auto max-w-xl">
                <div className="flex w-full flex-col items-center space-y-8 rounded-md p-6 lg:h-full lg:p-8">
                  <blockquote className="max-w-lg text-center text-lg font-medium italic">
                    "Et, dignissimos obcaecati. Recusandae praesentium doloribus vitae? Rem unde
                    atque mollitia!"
                  </blockquote>
                  <div className="text-center ">
                    <p>Leroy Jenkins</p>
                    <p>CEO of Company Co.</p>
                  </div>
                  <div className="flex space-x-2">
                    <button
                      type="button"
                      aria-label="Page 1"
                      className="size-2 rounded-full "></button>
                    <button
                      type="button"
                      aria-label="Page 2"
                      className="size-2 rounded-full "></button>
                    <button
                      type="button"
                      aria-label="Page 3"
                      className="size-2 rounded-full "></button>
                    <button
                      type="button"
                      aria-label="Page 4"
                      className="size-2 rounded-full"></button>
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* Footer */}

          {/* MAIN_FOOTER */}
          <footer className="w-full bg-white">
            <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
              <div className="md:flex md:justify-between">
                <div className="mb-6 md:mb-0">
                  <a href="https://flowbite.com/" className="flex items-center">
                    <img
                      src="/assets/images/amora-logo.png"
                      className="me-3 h-8"
                      alt="FlowBite Logo"
                    />
                    <span className="self-center whitespace-nowrap text-2xl font-semibold ">
                      AMORA
                    </span>
                  </a>
                </div>
                <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 sm:gap-6">
                  <div>
                    <h2 className="mb-6 text-sm font-semibold uppercase text-gray-900 ">
                      Resources
                    </h2>
                    <ul className="font-medium text-gray-500 ">
                      <li className="mb-4">
                        <a href="https://flowbite.com/" className="hover:underline">
                          Flowbite
                        </a>
                      </li>
                      <li>
                        <a href="https://tailwindcss.com/" className="hover:underline">
                          Tailwind CSS
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="mb-6 text-sm font-semibold uppercase text-gray-900 ">
                      Follow us
                    </h2>
                    <ul className="font-medium text-gray-500 ">
                      <li className="mb-4">
                        <a
                          href="https://github.com/themesberg/flowbite"
                          className="hover:underline ">
                          Github
                        </a>
                      </li>
                      <li>
                        <a href="https://discord.gg/4eeurUVvTy" className="hover:underline">
                          Discord
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    <h2 className="mb-6 text-sm font-semibold uppercase text-gray-900 ">Legal</h2>
                    <ul className="font-medium text-gray-500 ">
                      <li className="mb-4">
                        <a href="#" className="hover:underline">
                          Privacy Policy
                        </a>
                      </li>
                      <li>
                        <a href="#" className="hover:underline">
                          Terms &amp; Conditions
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8 " />
              <div className="sm:flex sm:items-center sm:justify-between">
                <span className="text-sm text-gray-500 sm:text-center ">
                  © 2023{' '}
                  <a href="https://flowbite.com/" className="hover:underline">
                    PT Amora Gunung Mas
                  </a>
                  . All Rights Reserved.
                </span>
                <div className="mt-4 flex sm:mt-0 sm:justify-center">
                  <a href="#" className="text-gray-500 hover:text-gray-900 ">
                    <svg
                      className="size-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 8 19">
                      <path
                        fillRule="evenodd"
                        d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Facebook page</span>
                  </a>
                  <a href="#" className="ms-5 text-gray-500 hover:text-gray-900 ">
                    <svg
                      className="size-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 21 16">
                      <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z" />
                    </svg>
                    <span className="sr-only">Discord community</span>
                  </a>
                  <a href="#" className="ms-5 text-gray-500 hover:text-gray-900 ">
                    <svg
                      className="size-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 17">
                      <path
                        fillRule="evenodd"
                        d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Twitter page</span>
                  </a>
                  <a href="#" className="ms-5 text-gray-500 hover:text-gray-900 ">
                    <svg
                      className="size-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 .333A9.911 9.911 0 0 0 6.866 19.65c.5.092.678-.215.678-.477 0-.237-.01-1.017-.014-1.845-2.757.6-3.338-1.169-3.338-1.169a2.627 2.627 0 0 0-1.1-1.451c-.9-.615.07-.6.07-.6a2.084 2.084 0 0 1 1.518 1.021 2.11 2.11 0 0 0 2.884.823c.044-.503.268-.973.63-1.325-2.2-.25-4.516-1.1-4.516-4.9A3.832 3.832 0 0 1 4.7 7.068a3.56 3.56 0 0 1 .095-2.623s.832-.266 2.726 1.016a9.409 9.409 0 0 1 4.962 0c1.89-1.282 2.717-1.016 2.717-1.016.366.83.402 1.768.1 2.623a3.827 3.827 0 0 1 1.02 2.659c0 3.807-2.319 4.644-4.525 4.889a2.366 2.366 0 0 1 .673 1.834c0 1.326-.012 2.394-.012 2.72 0 .263.18.572.681.475A9.911 9.911 0 0 0 10 .333Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">GitHub account</span>
                  </a>
                  <a href="#" className="ms-5 text-gray-500 hover:text-gray-900 ">
                    <svg
                      className="size-4"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20">
                      <path
                        fillRule="evenodd"
                        d="M10 0a10 10 0 1 0 10 10A10.009 10.009 0 0 0 10 0Zm6.613 4.614a8.523 8.523 0 0 1 1.93 5.32 20.094 20.094 0 0 0-5.949-.274c-.059-.149-.122-.292-.184-.441a23.879 23.879 0 0 0-.566-1.239 11.41 11.41 0 0 0 4.769-3.366ZM8 1.707a8.821 8.821 0 0 1 2-.238 8.5 8.5 0 0 1 5.664 2.152 9.608 9.608 0 0 1-4.476 3.087A45.758 45.758 0 0 0 8 1.707ZM1.642 8.262a8.57 8.57 0 0 1 4.73-5.981A53.998 53.998 0 0 1 9.54 7.222a32.078 32.078 0 0 1-7.9 1.04h.002Zm2.01 7.46a8.51 8.51 0 0 1-2.2-5.707v-.262a31.64 31.64 0 0 0 8.777-1.219c.243.477.477.964.692 1.449-.114.032-.227.067-.336.1a13.569 13.569 0 0 0-6.942 5.636l.009.003ZM10 18.556a8.508 8.508 0 0 1-5.243-1.8 11.717 11.717 0 0 1 6.7-5.332.509.509 0 0 1 .055-.02 35.65 35.65 0 0 1 1.819 6.476 8.476 8.476 0 0 1-3.331.676Zm4.772-1.462A37.232 37.232 0 0 0 13.113 11a12.513 12.513 0 0 1 5.321.364 8.56 8.56 0 0 1-3.66 5.73h-.002Z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="sr-only">Dribbble account</span>
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </main>
      </div>
    </HelmetProvider>
  );
}

export default App;
